import React, { useState } from "react";
import { useGetApiAsTenant } from "../../../tools/axiosFunctions";
import { useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Button,
  MenuItem,
  Select,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FindReplace, Search } from "@mui/icons-material";

const groupComponents = () => {};
export function CategoryDropdown({ handleSelect, label, props }) {
  const getApiAsTenant = useGetApiAsTenant();
  const { t } = useTranslation("global");
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    getApiAsTenant("/category/").then((response) =>
      setCategoryList(response.data)
    );
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={props?.id}>{label}</InputLabel>
      <Select
        {...props}
        label={label}
        id={props?.id}
        onChange={(e) =>
          handleSelect(categoryList.find((cat) => cat.id === e.target.value))
        }
      >
        {categoryList.map((category) => (
          <MenuItem value={category.id}>
            {category.shared
              ? t(
                  `categories.name.${category.displayName}`,
                  `${category.displayName.substring(7)} ${t(
                    "categories.name.shared"
                  )}`
                )
              : t(
                  `categories.name.${category.displayName}`,
                  category.displayName
                )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function GroupSearch({ setGroupInfo }) {
  const [searchText, setSearchText] = useState("");
  const getApiAsTenant = useGetApiAsTenant();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [noGroups, setNoGroups] = useState(false);
  // Handle text input change
  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  // Handle search when enter is pressed or button is clicked
  const handleSearch = () => {
    if (searchText.trim()) {
      setLoading(true);
      setNoGroups(false);
      setGroups([]);
      getApiAsTenant(`/category/search/${searchText.toLowerCase()}`)
        .then((response) => {
          setGroups(response.data);
          setLoading(false);
        })
        .catch(() => {
          setGroups([]);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    searchText.trim() && groups.length === 0 && !loading
      ? setNoGroups(searchText.trim())
      : setNoGroups(false);
    setGroupInfo(groups);
  }, [groups]);

  // Handle pressing the Enter key
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 0, my: 1 }}>
      {/* Search input field */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchText}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress} // Trigger search on "Enter"
        error={noGroups}
        helperText={noGroups && `No groups with "${noGroups}" found`}
      />

      {/* Search button */}
      <Button
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={handleSearch} // Trigger search on button click
        sx={{ height: "56px" }} // Ensure button matches the height of the text field
      >
        {loading ? <CircularProgress /> : <Search />}
      </Button>
    </Box>
  );
}

export function DropdownGroupSelector({ groupInfo, handleSelect }) {
  const [optionsList, setOptionsList] = useState(false);
  useEffect(() => {
    setOptionsList(
      groupInfo.map((group) => ({
        label: group.displayName,
        id: group.id,
        members: group.managedMembers,
      }))
    );
  }, [groupInfo]);

  const handleChange = (members) => {
    handleSelect &&
      handleSelect(
        members.map((item) => ({
          id: item.id,
          label: item.deviceName,
        }))
      );
  };

  return (
    <Autocomplete
      options={optionsList}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label="Select a group" variant="outlined" />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.label}
        </Box>
      )}
      // Optional: Control value and change handler
      onChange={(event, value) => {
        handleChange(value.members);
      }}
    />
  );
}

export default groupComponents;
