import React, { useContext, useEffect, useState } from "react";
import { BackofficeContext } from "../../../tools/BackofficeContext";
import TaskDisplay from "./TaskDisplay"
import { w3cwebsocket } from "websocket";
import { useGetSocketEndpoint } from "../../../tools/axiosFunctions";
import { Box } from "@mui/material";
function TaskList() {
  const endpoint = useGetSocketEndpoint()
  const { tenantId } = useContext(BackofficeContext);
  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    const socket = new w3cwebsocket(
      `${endpoint}/ws/bcohistory/${tenantId}/`
    );
    socket.onmessage = (message) => setTasks(JSON.parse(message.data));
    return () => {
      if (socket.readyState == w3cwebsocket.OPEN) {
        socket.close();
      }
    };
  }, []);

  return (
    <>
    <Box sx={{paddingY:2,maxHeight:"75vh",overflowY:'auto'}}>
      {tasks.map((task) => (
        <TaskDisplay task={task} />
      ))}</Box>
    </>
  );
}

export default TaskList;
