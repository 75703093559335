import {
  ApartmentOutlined,
  GroupOutlined,
  LocalPrintshopOutlined,
  MonitorOutlined,
  ScheduleOutlined,
  SettingsOutlined,
  StorefrontOutlined,
  TaskOutlined,
  TerminalOutlined,
} from "@mui/icons-material";
import React from "react";
import ModuleButton from "./ModuleButton";
import { Box } from "@mui/material";
function HomeMenu({select}) {
  const options = [
    { title: "App Store", Icon: StorefrontOutlined,module:"appstore" },
    { title: "App Management", Icon: TerminalOutlined,module:"appMgmt" },
    { title: "Group Management", Icon: GroupOutlined,module:"groupMgmt" },
    { title: "Device Management", Icon: MonitorOutlined,module:"deviceMgmt" },
    { title: "Policy Management", Icon: TaskOutlined,module:"policyMgmt" },
    { title: "Printer Managemnet", Icon: LocalPrintshopOutlined,module:"printerMgmt" },
    { title: "History", Icon: ScheduleOutlined,implemented:true },
    { title: "Numiato Settings", Icon: SettingsOutlined,module:"settings" },
    { title: "Backoffice Tasks", Icon: ApartmentOutlined,module:"backoffice",implemented:true },
  ];
  return (
    <Box sx={{ display: "flex", padding:2,flexWrap:"wrap",color:"#333" }}>
      {options.map((opt, index) => (
        <ModuleButton key={`mdl_${index}`} select={select} {...opt}/>
      ))}
    </Box>
  );
}

export default HomeMenu;
