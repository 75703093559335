import { Table, TableBody,TableRow,Skeleton,TableCell } from "@mui/material";
import React from "react";

export default function loading(){}

export function LoadingTable() {
  return (
    <>
      <Table>
        <TableBody>
          {[...Array(10)].map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Skeleton animation="wave" variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

