import { Tolgee, TolgeeProvider, DevTools, FormatSimple } from "@tolgee/react";

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    defaultLanguage: "EN",
    availableLanguages: ["EN", "DE"],
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
    inputPrefix: "<%-",
    inputSuffix: "%>",
  });

export default tolgee;

export const TolgeeDecorator = (storyFn) => (
  <TolgeeProvider tolgee={tolgee}>{storyFn()}</TolgeeProvider>
);
