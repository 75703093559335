import React,{useContext} from "react";
import { Login } from "@microsoft/mgt-react";
import { Providers } from "@microsoft/mgt-element";
import { List, ListItem, Typography } from "@mui/material";
import { BackofficeContext } from "../../tools/BackofficeContext";
import "./UserButton.css"
function UserButton() {
  const {setAuthenticated} = useContext(BackofficeContext)
  const LoginCommandsTemplate = (props) => {
    const { AADSignOut } = props.dataContext;
    const handleSignOut = () => {
      sessionStorage.clear();
      setAuthenticated(false)
      Providers.globalProvider.logout();
    };
    return (
      <div>
        <List>
          <ListItem button onClick={handleSignOut}>
            <Typography variant="body1" textAlign="center">Sign out</Typography>
          </ListItem>
        </List>
      </div>
    );
  };
  const Empty=()=>{
    return <></>
  }
  return (
    <div className="relative">
      <Login className="login" dir="rtl">
        <LoginCommandsTemplate template="flyout-commands"></LoginCommandsTemplate>
        <Empty template="flyout-person-details"></Empty>
      </Login>
    </div>
  );
}

export default UserButton;
