import React,{} from 'react'

function Home() {
  return (<>
  <div>
    Wir sind dahoam :)
  </div>
  </>
    
  )
}

export default Home