import { Visibility } from "@mui/icons-material";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Skeleton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Box,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { GetApi, PostApi } from "../../../tools/axiosFunctions";
import { BackofficeContext } from "../../../tools/BackofficeContext";
import moment from "moment";
import { useEffect } from "react";
import EventsList from "./EventsList";

function AppleClassroomTab({ tenant }) {
  const { environment, makeRefresh } = useContext(BackofficeContext);
  const [sftpPassword, setSftpPassword] = useState("***************");
  const [events, setEvents] = useState("loading");
  const [updateDialog, setUpdateDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleUpdateDialog = () => setUpdateDialog(!updateDialog);
  const handleViewPassword = () => {
    setSftpPassword("loading");
    GetApi(environment, `/auth/backoffice/tenant/${tenant.tenant_id}/sftp_pwd/`)
      .then((response) => {
        setSftpPassword(response.data);
      })
      .catch(setSftpPassword("***************"));
  };
  const handleForceUpdate = () => {
    PostApi(
      environment,
      `/auth/backoffice/tenant/${tenant.tenant_id}/updateasmc/`,
      {}
    );
  };
  const handleUpdateInfo = () => {
    setLoading(true);
    const payload = {
      sftp_url: document.getElementById("sftp_url").value,
      sftp_user: document.getElementById("sftp_user").value,
      sftp_password: document.getElementById("sftp_password").value,
    };
    PostApi(
      environment,
      `/auth/backoffice/tenant/${tenant.tenant_id}/updatesftp/`,
      payload
    ).then((r) => {
      makeRefresh();
    });
    setUpdateDialog(false);
    setLoading(false);
  };
  useEffect(() => {
    GetApi(environment, `/tasks/backoffice/events/${tenant.tenant_id}/`).then(
      (response) => {
        let filteredEvents = [];
        response.data.map(
          (ev) =>
            ["1000", "1001", "1002", "1003"].includes(ev.code) &&
            filteredEvents.push(ev)
        );
        setEvents(filteredEvents);
      }
    );
  }, [makeRefresh]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} lg={4}>
        <Typography variant="h6">Details</Typography>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem>
            <ListItemText primary={tenant.tenant_id} secondary="Tenant Id" />
          </ListItem>
          <ListItem>
            <ListItemText primary={tenant.name} secondary="Tenant Name" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={tenant.appleClassroom?.sftp_url || "--"}
              secondary="SFTP URL"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={tenant.appleClassroom?.sftp_user || "--"}
              secondary="SFTP User"
            />
          </ListItem>
          <ListItem
            secondaryAction={
              tenant.appleClassroom?.sftp_user && (
                <IconButton
                  edge="start"
                  aria-label="view"
                  onClick={handleViewPassword}
                >
                  <Visibility />
                </IconButton>
              )
            }
          >
            <ListItemText
              primary={
                sftpPassword === "loading" ? (
                  <Skeleton sx={{ width: 300, height: 40 }} />
                ) : (
                  sftpPassword
                )
              }
              secondary="SFTP Password"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                tenant.appleClassroom?.last_update
                  ? moment(tenant.appleClassroom?.last_update).format(
                      "DD.MM.YYYY HH:mm:ss"
                    )
                  : "Never"
              }
              secondary="SFTP info last updated"
            />
          </ListItem>
        </List>
        <Button onClick={toggleUpdateDialog}>Update SFTP information</Button>
      </Grid>
      <Grid item xs={0} sm={0} lg={2}></Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Box justifyContent="flex-end" display="flex">
          <Button variant="contained" onClick={handleForceUpdate}>
            Force Update Apple Classroom information
          </Button>
        </Box>
        <Typography variant="h6">Events</Typography>
        <EventsList events={events} date small />
      </Grid>
      <Dialog open={updateDialog} onClose={toggleUpdateDialog} maxWidth="xs">
        <DialogTitle>Update SFTP Information</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { my: 1 },
            }}
          >
            <TextField
              id="sftp_url"
              label="SFTP URL"
              defaultValue={
                tenant.appleClassroom?.sftp_url ||
                "upload.connect.claris.com"
              }
              fullWidth
            />
            <TextField
              id="sftp_user"
              label="SFTP User"
              defaultValue={tenant.appleClassroom?.sftp_user}
              fullWidth
            />
            <TextField
              id="sftp_password"
              label="SFTP Password"
              defaultValue={sftpPassword}
              fullWidth
            />
            <Button onClick={handleUpdateInfo} disabled={loading} fullWidth>
              {loading ? `loading` : `Save Changes`}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default AppleClassroomTab;
