import React, { useContext, useEffect, useState } from "react";
import { useGetApiFromContext, GetApi } from "../../tools/axiosFunctions";
import { Box, Autocomplete, TextField, Skeleton } from "@mui/material";
import { BackofficeContext } from "../../tools/BackofficeContext";
function TenantPicker({ setName }) {
  const getApiFromContext = useGetApiFromContext();
  const { environment, setTenantId } = useContext(BackofficeContext);
  const [tenantList, setTenantList] = useState(false);
  const getData = async () => {
    getApiFromContext("/auth/backoffice/tenant/").then((response) => {
      setTenantList(
        response.data.map((t) => ({ label: t.name, id: t.tenant_id }))
      );
    });
  };
  useEffect(() => {
    setTenantId(false);
    setTenantList(false);

    getData();
  }, [environment]);

  return (
    <>
      <Box sx={{ width: 600 }}>
        {tenantList ? (
          <Autocomplete
            options={tenantList}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Tenant"
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.label}
              </Box>
            )}
            // Optional: Control value and change handler
            onChange={(event, value) => {
              setTenantId(value.id);
              setName && setName(value.label);
            }}
          />
        ) : (
          <Skeleton variant="rectangular" width={600} />
        )}
      </Box>
    </>
  );
}

export default TenantPicker;
