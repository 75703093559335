import { createContext, useContext } from "react";

export const BackofficeContext = createContext({
  authenticated: false,
  setAuthenticated: () => {},
  locToken: null,
  setLocToken: () => {},
  uatToken: null,
  setUatToken: () => {},
  prdToken: null,
  setPrdToken: () => {},
  tenantId: null,
  setTenantId: () => {},
  refresh: null,
  makeRefresh: () => {},
  environment:null,
  setEnvironment:()=>{}
});
