import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";

function EventsList({ events, tenantName, tenantId, date, code, small }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader size={small && "small"}>
          <TableHead>
            <TableRow>
              {tenantId && <TableCell>TenantId</TableCell>}
              {tenantName && <TableCell>Tenant Name</TableCell>}
              {date && <TableCell>Timestamp</TableCell>}
              {code && <TableCell>Event Code</TableCell>}
              <TableCell>Event</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events === "loading" ? (
              <>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tenantId && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {tenantName && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {date && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {code && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  <TableCell>
                    <Skeleton variant="rectangular" width={150} height={20} />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tenantId && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {tenantName && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {date && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {code && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  <TableCell>
                    <Skeleton variant="rectangular" width={150} height={20} />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tenantId && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {tenantName && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {date && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  {code && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={20} />
                    </TableCell>
                  )}
                  <TableCell>
                    <Skeleton variant="rectangular" width={150} height={20} />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              events.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tenantId && <TableCell>{row.target}</TableCell>}
                  {tenantName && <TableCell>{row.targetName}</TableCell>}
                  {date && <TableCell>{moment(row.timestamp).format("DD.MM.YYYY HH:mm")}</TableCell>}
                  {code && <TableCell>{row.code}</TableCell>}
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default EventsList;
