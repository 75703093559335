import React, { useEffect,useState } from "react";
import EnvironmentCombo from "../system/layout/EnvironmentCombo";
import { Grid } from "@mui/material";
import DataTable from "../system/layout/DataTable";
function PageTemplate({
  source,
  target,
  setSource,
  setTarget,
  master,
  sourceObjects,
  targetObjects,
  gatherData,
  mRefresh
}) {
  const [lRefresh, setLRefresh] = useState(0)
  const refresh = ()=>setLRefresh(p=>p+1)
  const handleChangeSource = (e) => {
    if (e.target.value !== target) {
      setSource(e.target.value);
    } else {
      setTarget(source);
      setSource(e.target.value);
    }
    refresh();
  };
  const handleChangeTarget = (e) => {
    if (e.target.value !== source) {
      setTarget(e.target.value);
    } else {
      setSource(target);
      setTarget(e.target.value);
    }
    refresh();
  };
  useEffect(() => {
    gatherData && gatherData()
  }, [mRefresh,lRefresh]);

  return (
    <>
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={6} sx={{ p: 2 }}>
          <Grid
            sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}
            align="left"
            direction="column"
          >
            <EnvironmentCombo
              label="Source"
              onChange={handleChangeSource}
              value={source}
              id="cmbSource"
            />
          </Grid>

          {sourceObjects && (
            <DataTable data={sourceObjects} display={master} selectable />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid
            sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}
            align="left"
            direction="column"
          >
            <EnvironmentCombo
              label="Destination"
              onChange={handleChangeTarget}
              value={target}
              id="cmbDestionation"
            />
          </Grid>

          {targetObjects && <DataTable data={targetObjects} display={master} />}
        </Grid>
      </Grid>
    </>
  );
}

export default PageTemplate;
