import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { Providers } from "@microsoft/mgt-element";
import { BrowserRouter } from "react-router-dom";
import i18n from "./tools/i18n";
import { I18nextProvider } from "react-i18next";
import tolgee from "./tools/tolgeeFn";
Providers.globalProvider = new Msal2Provider({
  clientId: process.env.REACT_APP_CLIENT_ID,
  authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  scopes: [
    "offline_access",
    "User.ReadBasic.All",
    "User.Read.All",
    "Group.Read.All",
    "openid",
    "People.Read",
    "GroupMember.Read.All",
    "User.Read",
    "profile",
  ],
});



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
