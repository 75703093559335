import { Grid, Typography } from "@mui/material";
import React from "react";

export default function PageLayout() {
  return <></>;
}

export function PageTitle({ children }) {
  return <Typography variant="h4" sx={{justifyContent:"space-between",display:"flex"}}>{children}</Typography>;
}
export function PageSubtitle({ children }) {
  return <Typography variant="h6" sx={{alignContent:'center',alignItems:"center",display:"flex"}}>{children}</Typography>;
}


export function PageButtonBar({children}){
  return <Grid container sx={{p:2,mx:2,justifyContent:"flex-end",display:"flex"}}>
    {children}
  </Grid>
}