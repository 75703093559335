import React, { useContext, useState, useEffect } from "react";
import BackofficeTask, { BackofficeTaskList } from "./BackofficeTask";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { AppstoreAppsTenantDropdown } from "../application/AppstoreApps";
import { BackofficeContext } from "../../../tools/BackofficeContext";
import {
  CategoryDropdown,
  DropdownGroupSelector,
  GroupSearch,
} from "../group/GroupComponents";
import ListSelector from "../../system/layout/ListSelector";

function TaskComponents() {
  return <div>TaskComponents</div>;
}

export default TaskComponents;

export const getBackofficeTaskBasePayload = () => {
  return {
    kind: "bko",
    target: "bko",
    detail: {
      task: "",
      args: [],
      kwargs: {},
    },
  };
};

export function ReplaceApplication({ setPayload }) {
  const { tenantId } = useContext(BackofficeContext);
  const [removeOld, setRemoveOld] = useState(false);
  const [unassignOld, setUnassignOld] = useState(false);
  const [oldApp, setOldApp] = useState(false);
  const [newApp, setNewApp] = useState(false);
  const toggleRemoveOld = () => {
    setRemoveOld(!removeOld);
    setUnassignOld(false);
    validate();
  };
  const toggleUnassignOld = () => {
    setRemoveOld(false);
    setUnassignOld(!unassignOld);
    validate();
  };
  const selectOldApp = (e) => {
    setOldApp(e.target.value);
    validate();
  };
  const selectNewApp = (e) => {
    setNewApp(e.target.value);
    validate();
  };
  const validate = () => {
    let valid = false;
    if (oldApp && newApp && oldApp != newApp) {
      let payload = getBackofficeTaskBasePayload();
      payload.detail.task = "application.backoffice.replace_application";
      payload.detail.kwargs = {
        oldAppId: oldApp,
        newAppId: newApp,
        tenantId: tenantId,
      };
      if (removeOld) {
        payload.detail.kwargs.extra = "delete";
      }
      if (unassignOld) {
        payload.detail.kwargs.extra = "unassign";
      }
      setPayload(payload);
    } else {
      setPayload(false);
    }
  };
  return (
    <Grid container sx={{ paddingY: 2 }}>
      <Grid item xs={12} sx={{ paddingY: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={removeOld} onChange={toggleRemoveOld} />}
          id="deleteOld"
          label="Delete old application"
        />
        <FormControlLabel
          control={
            <Checkbox checked={unassignOld} onChange={toggleUnassignOld} />
          }
          id="unassignOld"
          label="Unassign old application"
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingX: 2 }}>
        <AppstoreAppsTenantDropdown
          id="OldAppMenu"
          label="Old app"
          select={selectOldApp}
          installedOnly
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingX: 2 }}>
        <AppstoreAppsTenantDropdown
          id="NewAppMenu"
          label="New app"
          select={selectNewApp}
          uninstalledOnly
        />
      </Grid>
    </Grid>
  );
}

export function GroupCategoryAssignment({ setPayload }) {
  const { tenantId } = useContext(BackofficeContext);
  const [targetCategory, setTargetCategory] = useState(null);
  const [foundGroups, setFoundGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const validate = () => {
    if (targetCategory && selectedMembers.length > 0) {
      let payload = getBackofficeTaskBasePayload();
      payload.kind = "asg";
      payload.target = "dev";
      payload.detail.task = "device.tasks.task_assign_device";
      payload.detail.display = {
        count: selectedMembers.length,
        target: targetCategory.displayName,
      };
      payload.detail.kwargs = {
        tenantId: tenantId,
        detail: selectedMembers.map((member) => ({
          deviceId: member.id,
          categoryId: targetCategory.id,
        })),
      };
      setPayload(payload);
    }
  };
  useEffect(() => {
    validate();
  }, [selectedMembers, targetCategory]);

  return (
    <Grid container sx={{ paddingY: 2 }}>
      <Grid item xs={12}>
        <GroupSearch setGroupInfo={setFoundGroups} />
      </Grid>
      <Grid item xs={12}>
        {foundGroups.length > 0 && (
          <DropdownGroupSelector
            groupInfo={foundGroups}
            handleSelect={setMembers}
          />
        )}
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        {members.length > 0 && (
          <ListSelector
            setAudience={setSelectedMembers}
            available={members}
            title="Device"
          />
        )}
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <CategoryDropdown
          label="Select target category"
          handleSelect={setTargetCategory}
        />
      </Grid>
    </Grid>
  );
}
