import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,Paper,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

function AudiencePopup({ audience }) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  return (
    <>
      <Button variant="outlined" sx={{ mx: 2 }} onClick={handleClickOpen}>
        View list
      </Button>
      <Dialog
        open={open}
        onClose={handleClickClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ sx: { position: "relative" } }}
      >
        <DialogTitle>
          Selected Tenants
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Tenant Id</TableCell>
                  <TableCell>Tenant Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {audience.map((row) => (
                  <TableRow key={`audience${row.id}`}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AudiencePopup;
