import React, { useEffect, useState } from "react";
import {
  useGetApiAsTenant,
  useGetApiFromContext,
} from "../../../tools/axiosFunctions";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
/**
 * Queries the apps following a tenant
 * @param {function} select
 * @returns
 */
export function AppstoreAppsTenantDropdown({
  select,
  label,
  installedOnly,
  uninstalledOnly,
  props,
}) {
  const getApiAsTenant = useGetApiAsTenant();
  const [appList, setAppList] = useState([]);
  useEffect(() => {
    getApiAsTenant("/appstore/").then((response) => {
      setAppList(response.data);
    });
  }, []);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel htmlFor={props?.id}>{label}</InputLabel>
        <Select {...props} label={label} id={props?.id} onChange={select}>
          {installedOnly
            ? appList.map((opt) => {
                return (
                  opt.isInstalled && (
                    <MenuItem value={opt.id}>{opt.display_name}</MenuItem>
                  )
                );
              })
            : uninstalledOnly
              ? appList.map((opt) => {
                  return (
                    !opt.isInstalled && (
                      <MenuItem value={opt.id}>{opt.display_name}</MenuItem>
                    )
                  );
                })
              : appList.map((opt) => (
                  <MenuItem value={opt.id}>{opt.display_name}</MenuItem>
                ))}
        </Select>
      </FormControl>
    </>
  );
}
function Subheader(props) {
  return <ListSubheader {...props} />;
}

Subheader.muiSkipListHighlight = true;
const AppstoreApps = () => {};
export default AppstoreApps;

export function AppstoreAllAppsDropdown({ select, label, props }) {
  const getApiFromContext = useGetApiFromContext();
  const [appList, setAppList] = useState([]);
  useEffect(() => {
    getApiFromContext("/appstore/data/application/").then((response) => {
      setAppList(response.data);
    });
  }, []);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel htmlFor={props?.id}>{label}</InputLabel>
        <Select {...props} label={label} id={props?.id} onChange={select}>
          {appList.map((opt) => (
            <MenuItem value={opt.id}>{`${opt.display_name} - ${opt.application_type}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
