import { Tooltip } from "@mui/material";
import React from "react";

export default function LicensingItems() {
  return <></>;
}

export const NumiatoStatusIcon = (status) => {
  switch (status) {
    case "1000":
    case "1001":
      return <Tooltip title={`Trial ${status}`}>🟠</Tooltip>;
    case "1010":
      return <Tooltip title={`OK ${status}`}>🟢</Tooltip>;
    case "1003":
      return <Tooltip title={`Limited ${status}`}>🔸</Tooltip>;
    case "1101":
    case "1110":
    case "1201":
      return <Tooltip title={`Expired ${status}`}>🔴</Tooltip>;
    case "1002":
    case "1012":
      return <Tooltip title={`Expiring ${status}`}>🟡</Tooltip>;
    default:
      return <Tooltip title={`Unknown ${status}`}>⚪️</Tooltip>;
  }
};

export const NumiatoStatus = (status) => {
  switch (status) {
    case "1000":
      return (
        <Tooltip
          title={`Initial state for all tenants, right after the onboarding process is ran, no additional information of the tenant has been added yet`}
        >
          1000 - Onboarded
        </Tooltip>
      );
    case "1001":
      return (
        <Tooltip
          title={`The product is on its trial period, all features fully operational`}
        >
          1001 - Active Trial Period
        </Tooltip>
      );

    case "1002":
      return (
        <Tooltip
          title={`The product is on its trial period, all features fully operational. Expiring soon warning is displayed`}
        >
          1002 - Active Trial Period expiring
        </Tooltip>
      );
    case "1003":
      return (
        <Tooltip
          title={`The product is on limited support. Migrating to premium info shown`}
        >
          1003 - Limited support
        </Tooltip>
      );
    case "1010":
      return (
        <Tooltip
          title={`The product is fully licensed, all features fully operational`}
        >
          1010 - Active license
        </Tooltip>
      );
    case "1012":
      return (
        <Tooltip
          title={`The product is fully licensed, all features fully operational. Expiring soon warning is displayed`}
        >
          1012 - Active license expiring
        </Tooltip>
      );
    case "1101":
      return (
        <Tooltip
          title={`Trial period expired, only restricted features allowed`}
        >
          1101 - Expired Trial period
        </Tooltip>
      );
    case "1110":
      return (
        <Tooltip title={`License expired, only restricted features allowed`}>
          1110 - Expired License
        </Tooltip>
      );
    case "1200":
      return (
        <Tooltip title={`Tenant offboarded, no further access possible`}>
          1200 - Offboarded
        </Tooltip>
      );

    case "1201":
      return (
        <Tooltip title={`Tenant was offboarded, re-onboarding requested`}>
          1201 - Returning
        </Tooltip>
      );
    default:
      return <Tooltip title={`State received is not valid`}>Unknown</Tooltip>;
  }
};
export const Vis365Status = (status) => {
  switch (status) {
    case "501":
      return (
        <Tooltip
          title={`No license is active, initial state fully functional and free of charge`}
        >
          501 - Free use before trial
        </Tooltip>
      );
    case "502":
      return (
        <Tooltip title={`Trial period expired, tenant blocked`}>
          502 - Expired trial period
        </Tooltip>
      );
    case "503":
      return (
        <Tooltip title={`Valid license expired, tenant blocked`}>
          503 - Expired license
        </Tooltip>
      );
    case "510":
      return (
        <Tooltip title={`Product available, trial and expiration alert shown`}>
          510 - Active trial period
        </Tooltip>
      );
    case "521":
      return (
        <Tooltip title={`Product licensed and fully operational`}>
          521 - Active License Extension Disabled
        </Tooltip>
      );
    case "522":
      return (
        <Tooltip
          title={`Product licensed and fully operational, license can be extended`}
        >
          522 - Active License Extension Enabled
        </Tooltip>
      );
    case "523":
      return (
        <Tooltip
          title={`Product licensed and fully operational, expiration warning shown`}
        >
          523 - Active License Soon Expiring Warning
        </Tooltip>
      );
    case "600":
      return (
        <Tooltip title={`Users with terminated access`}>
          600 - Terminated access
        </Tooltip>
      );

    default:
      return <Tooltip title={`Unknown value received`}>Unknown</Tooltip>;
  }
};
