import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Menu,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ddopt = [
  { id: 1, label: "Option 1" },
  { id: 2, label: "Option 2" },
  { id: 3, label: "Option 3" },
];

const acopt = {
  1: ["Apple", "Banana", "Cherry"],
  2: ["Carrot", "Broccoli", "Spinach"],
  3: ["Chicken", "Beef", "Fish"],
};

function DropdownFilterOptions({ options, handleFilter }) {
  const dropdownOptions =
    (options && [
      ...Object.keys(options).map((opt) => {
        return { id: opt, label: opt };
      }),
    ]) ||
    ddopt;
  const autocompleteOptions = (options && options) || acopt;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(
    dropdownOptions[0]
  );
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [autocompleteItems, setAutocompleteItems] = useState(
    autocompleteOptions[selectedDropdownOption.id]
  );
  useEffect(() => {
    handleFilter &&
      handleFilter(selectedDropdownOption.label, autocompleteValue);
  }, [autocompleteValue]);
  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownSelect = (option) => {
    setSelectedDropdownOption(option);
    setAutocompleteItems(autocompleteOptions[option.id]);
    setAutocompleteValue(""); // Clear the Autocomplete value
    setAnchorEl(null); // Close the menu
  };

  return (
    <Grid container sx={{ display: "flex", alignItems: "center",my:2 }}>
      <Grid item>
        <Autocomplete
          value={autocompleteValue}
          onChange={(event, newValue) => setAutocompleteValue(newValue)}
          options={autocompleteItems}
          renderInput={(params) => (
            <Grid container sx={{ display: "flex",px:0 }}>
              <Grid item xs={4} >
                <Button
                  variant="contained"
                  onClick={handleDropdownClick}
                  endIcon={<ArrowDropDownIcon />}
                  sx={{ height:"100%",width:"100%" }}
                >
                  {selectedDropdownOption.label}
                </Button>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  {...params}
                  // sx={{ width: 350 }}
                  label={selectedDropdownOption.label}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
          sx={{ width: 800 }}
        />
        {/* <Button
        variant="contained"
        onClick={handleDropdownClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {selectedDropdownOption.label}
      </Button> */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleDropdownClose}
        >
          {dropdownOptions.map((option) => (
            <MenuItem
              key={option.id}
              onClick={() => handleDropdownSelect(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
}

export default DropdownFilterOptions;
