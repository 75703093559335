import {
  ApartmentOutlined,
  CheckCircleOutlined,
  ExpandMore,
  HighlightOffOutlined,
  PersonOutlineOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  CircularProgress,
  Grid,
  Icon,
  Box,
  Typography,
  AccordionDetails,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
} from "@mui/material";
import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { green } from "@mui/material/colors";
function TaskDisplay({ task }) {
  const { t } = useTranslation("global");

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            {task.state == "new" ? (
              <>
                {task.progress == 100 ? (
                  <StatusLogo status="css" />
                ) : (
                  <StatusLogo status="wip" />
                )}
              </>
            ) : (
              <StatusLogo status={task.state} />
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body">
              {moment(task.start_time).isValid()
                ? moment(task.start_time).format("DD.MM.YY   HH:mm")
                : ""}
              -
              {moment(task.end_time).isValid()
                ? moment(task.end_time).format("HH:mm")
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body">
              {t(`tasks.${task.kind}.${task.target}`, task.display)}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body">
              <RequesterLogo isBackoffice={task.isBackoffice} />
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                Requester
              </TableCell>
              <TableCell align="left">{task.requester}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                Last updated
              </TableCell>
              <TableCell align="left">
                {moment(task.last_updated).format("DD.MM.YY HH:mm")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                State
              </TableCell>
              <TableCell align="left">{t(`state.${task.state}`)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                Backoffice task
              </TableCell>
              <TableCell align="left">
                {t(`words.${task.isBackoffice ? "yes" : "no"}`)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                Status
              </TableCell>
              <TableCell align="left">
                {task.status}
              </TableCell>
            </TableRow>
            {task.display?.details &&
              Object.entries(task.display.details).map(
                ([key, value], index) => (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: "bold" }}
                    >
                      {key}
                    </TableCell>
                    <TableCell align="left">{value}</TableCell>
                  </TableRow>
                )
              )}
          </Table>
        </TableContainer>
        {task.results.map((result) => (
          <TaskResult result={result} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default TaskDisplay;

function RequesterLogo({ isBackoffice }) {
  return isBackoffice ? <ApartmentOutlined /> : <PersonOutlineOutlined />;
}

function StatusLogo({ status }) {
  switch (status) {
    case "css":
      return <CheckCircleOutlined style={{ color: "green" }} />;
    case "cww":
    case "cwr":
      return <WarningAmberRounded style={{ color: "yellow" }} />;
    case "cwe":
    case "cer":
      return <HighlightOffOutlined style={{ color: "red" }} />;
    case "wip":
    case "new":
      return (
        <CircularProgress size={20} thickness={6} sx={{ color: "#ff6600" }} />
      );
    // default:
    //   return <Square />;
    //   break;
  }
}

function TaskResult({ result }) {
  const { t } = useTranslation("global");
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              <StatusLogo status={result.state} />
            </Grid>
            <Grid item>
              {t(
                `tasks.results.${result.title}.title`,
                `${result.title}`,
                result.display
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              {Object.entries(result.detail).map(([key, value], index) => {
                return value ? (
                  <>
                    <TableRow key={`${result.id}${result.index}`}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: "bold" }}
                      >
                        {t(`properties.${key}`)}
                      </TableCell>
                      <TableCell>
                        {["category", "group_tag", "target"].includes(key)
                          ? t(`categories.name.${value}`, value)
                          : t(`properties.${value}`, value)}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <></>
                );
              })}
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
