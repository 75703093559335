import React, { useState, useEffect } from "react";

import {
  Box,
  FormControl,
  List,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  ListSubheader,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Button,
  Typography,
  Tooltip,
  Skeleton,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddCircle, Delete, Loop } from "@mui/icons-material";
import { useGetApiFromContext } from "../../../tools/axiosFunctions";
export function ProfilesDropdown({ handleSelect, selected = "" }) {
  const { t } = useTranslation("global");
  const [policyObjects, setPolicyObjects] = useState({});
  const getApiFromContext = useGetApiFromContext();
  useEffect(() => {
    getApiFromContext("/policy/").then((response) =>
      setPolicyObjects(response.data)
    );
  }, []);

  return policyObjects ? (
    <FormControl fullWidth>
      <InputLabel htmlFor="profilesDropdown">Select a profile</InputLabel>
      <Select
        value={selected}
        id="profilesDropdown"
        label="Select a profile"
        onChange={handleSelect}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Object.entries(policyObjects).map(([tar, profileValues]) =>
          Object.entries(profileValues).map(([objk, profiles]) => [
            <ListSubheader>{`${t(`policy.${objk}.displayName`)} - ${tar}`}</ListSubheader>,
            profiles.map((profil) => [
              <MenuItem value={profil.id} key={profil.id}>
                {`${t(`policy.${profil.id}.displayName`)} ${profil.parameters.length > 0 ? `⚠️`:""} `}
              </MenuItem>,
              profil.childs &&
                profil.childs.map((child) => (
                  <MenuItem
                    sx={{ paddingLeft: 4 }}
                    value={child.id}
                    key={child.id}
                    disabled
                  >
                    {`${t(`policy.${child.id}.displayName`)} - ${profil.target}`}
                  </MenuItem>
                )),
            ]),
          ])
        )}
      </Select>
      <FormHelperText>Profiles with ⚠️ require parameters, use the "Submit Policy with Payload" for that.</FormHelperText>
    </FormControl>
  ) : (
    <Skeleton sx={{ width: 600, height: 40 }} variant="rectangular" />
  );
}
function PolicyComponents() {
  return <div>PolicyComponents</div>;
}

export default PolicyComponents;

export function ProfilesSelector({ handleSelect }) {
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [profileSelected, setProfileSelected] = useState("");
  const [profilesList, setProfilesList] = useState({});
  const getApiFromContext = useGetApiFromContext();
  useEffect(() => {
    getApiFromContext("/policy/").then((response) =>
      setProfilesList(response.data)
    );
  }, []);
  useEffect(() => {
    const result = [];
    Object.keys(profilesList).forEach((key) => {
      const nestedArray = profilesList[key];
      Object.keys(nestedArray).forEach((subkey) => {
        nestedArray[subkey].forEach((item) => result.push(item));
      });
    });
    setFlatennedProfiles(result);
  }, [profilesList]);

  const [flatennedProfiles, setFlatennedProfiles] = useState([]);
  const handleSelectProfile = (event) => setProfileSelected(event.target.value);
  const handleAddProfile = (profileId) => {
    const exist = selectedProfiles.find((p) => p.id === profileId);
    if (!exist) {
      const element = flatennedProfiles.find((p) => p.id === profileId);
      if (element) {
        setSelectedProfiles([element, ...selectedProfiles]);
      }
    }
    setProfileSelected("");
  };
  const handleRemoveProfile = (profileId) => {
    setSelectedProfiles((p) => p.filter((i) => i.id !== profileId));
    setProfileSelected("");
  };
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <ProfilesDropdown
          policyObjects={profilesList}
          selected={profileSelected}
          handleSelect={handleSelectProfile}
        />
        <Tooltip title="Add profile">
          <IconButton
            onClick={() => handleAddProfile(profileSelected)}
            sx={{ px: 2, color: "green" }}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove profile">
          <IconButton
            onClick={() => handleRemoveProfile(profileSelected)}
            sx={{ px: 2, color: "red" }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
        <Tooltip title="Start over">
          <IconButton onClick={() => setSelectedProfiles([])} sx={{ px: 2 }}>
            <Loop />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex" }}>
        <ProfilesDisplaybox
          profiles={selectedProfiles}
          handlePayload={handleSelect}
        />
      </Box>
    </Box>
  );
}

export function ProfilesDisplaybox({ profiles = [], handlePayload }) {
  const { t } = useTranslation("global");
  const [profilesList, setProfilesList] = useState(profiles);
  const [switchValues, setSwitchValues] = useState([]);
  useEffect(() => {
    setProfilesList(profiles);
    setSwitchValues(
      profiles.reduce((acc, item) => {
        acc[item.id] = false;
        if (item.childs.length > 0) {
          item.childs.forEach((child) => {
            acc[child.id] = false;
          });
        }
        return acc;
      }, {})
    );
  }, [profiles]);
  const handleSwitchChange = (id) => {
    setSwitchValues((prv) => ({ ...prv, [id]: !prv[id] }));
  };
  useEffect(() => {
    const payload = profilesList.map((profile) => ({
      profile: profile.id,
      enabled: switchValues[profile.id],
      parameters: [],
    }));
    handlePayload && handlePayload(payload);
  }, [switchValues]);

  return (
    <>
      <Box>
        <List>
          {profilesList.map((profile) => [
            <ListItem
              key={profile.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ListItemText
                sx={{ pr: 4 }}
                primary={t(`policy.${profile.id}.displayName`)}
              />
              <Switch
                checked={switchValues[profile.id]}
                onChange={() => handleSwitchChange(profile.id)}
              />
            </ListItem>,
            profile.childs &&
              profile.childs.map((child) => (
                <ListItem
                  key={profile.id}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ListItemText
                    sx={{ pl: 4, pr: 4 }}
                    primary={`${t(`policy.${child.id}.displayName`)}`}
                  />
                  <Switch
                    checked={switchValues[child.id]}
                    onChange={() => handleSwitchChange(child.id)}
                  />
                </ListItem>
              )),
          ])}
        </List>
      </Box>
    </>
  );
}

export function PolicyProfilesPayloadDropzone({ handleSelect }) {
  const [inputValue, setInputValue] = useState("");
  const [formattedJson, setFormattedJson] = useState("");

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFormatJson = () => {
    try {
      const parsedJson = JSON.parse(inputValue);
      const beautifiedJson = JSON.stringify(parsedJson, null, 2); // Beautify JSON
      setFormattedJson(beautifiedJson);
      handleSelect(parsedJson)
    } catch (error) {
      setFormattedJson("Invalid JSON");
    }
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          padding: 2,
          maxWidth: "600px",
          margin: "0 auto",
          display: "flex",
         maxHeight: "400px",
        }}
        xs={5}
      >
        {/* TextArea for pasting JSON */}
        <TextField
          label="Paste JSON"
          multiline
          fullWidth
          rows={6}
          variant="outlined"
          value={inputValue}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={2} sx={{p:2,width:"100%"}}>
        {/* Button to format JSON */}
        <Button
          variant="contained"
          onClick={handleFormatJson}
          sx={{ marginTop: 2,width:"100%" }}
        >
          Validate JSON
        </Button>
      </Grid>
      <Grid item xs={5} sx={{
         marginTop: 2,
         backgroundColor: "#f5f5f5",
         padding: 2,
         borderRadius: "4px",
         fontFamily: "monospace",
         whiteSpace: "pre-wrap",
         overflowX: "auto",
         maxHeight: "400px",
        }}>
        {/* Display formatted JSON */}
        
          <Typography variant="monospace">{formattedJson}</Typography>
      </Grid>
    </Grid>
  );
}
