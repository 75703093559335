import { ExpandMore, Info } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

export function BackofficeTaskList({ isValid, handleSubmit, children }) {
  return (
    <>
      <Box sx={{ paddingY: 2, maxHeight: "75vh", overflowY: "auto" }}>
        {children}
      </Box>
      <Box>
        <Button onClick={handleSubmit} disabled={!isValid} variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
}

function BackofficeTask({
  id,
  title,
  description,
  children,
  selected,
  onExpand,
}) {
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  return (
    <>
      <Accordion expanded={selected === id} onChange={() => onExpand(id)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">{title}</Typography>
          {description && (
            <IconButton onClick={() => setDescriptionVisible((p) => !p)}>
              <Info />
            </IconButton>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {description && descriptionVisible && (
            <Typography sx={{ mb: 2, whiteSpace: "pre-line" }} >
              {description}
            </Typography>
          )}
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default BackofficeTask;
