import { Box, Button, Typography, Badge } from "@mui/material";
import React from "react";

function ModuleButton({ title, Icon, select, implemented }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      {implemented ? (
        <>
          <Button
            variant="outlined"
            sx={{
              padding: 2,
              borderRadius: "16px",
              textTransform: "none",
              borderColor: "#e0e0e0",
              "&:hover": {
                borderColor: "#c0c0c0",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
              height: "120px",
            }}
            onClick={() => select(title)}
          >
            <Icon sx={{ fontSize: 40, color: "#000" }} />
            <Typography sx={{ mt: 1, color: "#000" }}>{title}</Typography>
          </Button>
        </>
      ) : (
        <>
          <Badge
            badgeContent="Soon"
            color="secondary"
            sx={{
              "& .MuiBadge-badge": {
                top: "10px", // Adjust badge position
                right: "10px", // Adjust badge position
                fontSize: "12px", // Adjust font size
                padding: "8px 12px", // Adjust padding for "Soon"
                borderRadius: "12px", // Round badge with more space
              },
            }}
          >
            <Button
              variant="outlined"
              sx={{
                padding: 2,
                borderRadius: "16px",
                textTransform: "none",
                borderColor: "#e0e0e0",
                "&:hover": {
                  borderColor: "#c0c0c0",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "250px",
                height: "120px",
              }}
              //onClick={() => select(title)}
            >
              <Icon sx={{ fontSize: 40, color: "#000" }} />
              <Typography sx={{ mt: 1, color: "#000" }}>{title}</Typography>
            </Button>
          </Badge>
        </>
      )}
    </Box>
  );
}

export default ModuleButton;
