import React, { useEffect, useState } from "react";
import { getBackofficeTaskBasePayload } from "../backoffice/TaskComponents";
import { AppstoreAllAppsDropdown } from "../application/AppstoreApps";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  InputLabel,
  MenuItem,
  Box,
} from "@mui/material";

import { usePostApiFromContext } from "../../../tools/axiosFunctions";
import {
  PolicyProfilesPayloadDropzone,
  ProfilesDropdown,
  ProfilesSelector,
} from "../policy/PolicyComponents";
function BulkTaskComponents() {
  return <div>BulkTaskComponents</div>;
}

export default BulkTaskComponents;
export const getBackofficeBulkTaskBasePayload = (audience) => {
  return {
    kind: "bko",
    target: "bko",
    detail: {
      task: "",
      args: [],
      kwargs: {},
    },
  };
};
export function BulkReplaceApplication({ audience, close }) {
  // Even tho is a bulk task, the endpoint receiving it is the normal backoffice endpoint
  const [removeOld, setRemoveOld] = useState(false);
  const [unassignOld, setUnassignOld] = useState(false);
  const [oldApp, setOldApp] = useState(false);
  const [newApp, setNewApp] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const postApiFromContext = usePostApiFromContext();
  const toggleRemoveOld = () => {
    setRemoveOld(!removeOld);
    setUnassignOld(false);
  };
  const toggleUnassignOld = () => {
    setRemoveOld(false);
    setUnassignOld(!unassignOld);
  };
  const selectOldApp = (e) => {
    setOldApp(e.target.value);
  };
  const selectNewApp = (e) => {
    setNewApp(e.target.value);
  };
  useEffect(() => {
    if (oldApp && newApp && oldApp != newApp) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [oldApp, newApp]);

  const handleSubmit = () => {
    let payload = getBackofficeTaskBasePayload();
    payload.detail.task = "application.backoffice.replace_application";
    payload.detail.kwargs = {
      oldAppId: oldApp,
      newAppId: newApp,
      tenantId: audience.map((tenant) => {
        return tenant.id;
      }),
    };
    if (removeOld) {
      payload.detail.kwargs.extra = "delete";
    }
    if (unassignOld) {
      payload.detail.kwargs.extra = "unassign";
    }
    postApiFromContext(`/tasks/backoffice/submit/`, payload).then(
      (response) => {
        close && close();
      }
    );
  };

  return (
    <Grid container sx={{ paddingY: 2 }}>
      <Grid item xs={12} sx={{ paddingY: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={removeOld} onChange={toggleRemoveOld} />}
          id="deleteOld"
          label="Delete old application"
        />
        <FormControlLabel
          control={
            <Checkbox checked={unassignOld} onChange={toggleUnassignOld} />
          }
          id="unassignOld"
          label="Unassign old application"
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingX: 2 }}>
        <AppstoreAllAppsDropdown
          id="OldAppMenu"
          label="Old app"
          select={selectOldApp}
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingX: 2 }}>
        <AppstoreAllAppsDropdown
          id="NewAppMenu"
          label="New app"
          select={selectNewApp}
        />
      </Grid>
      <Grid item sx={{ padding: 2 }}>
        <Button onClick={handleSubmit} disabled={!isValid} variant="contained">
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

function ScopeDropdown({ handleSelect, selected }) {
  return (
    <FormControl fullWidth sx={{ my: 2 }}>
      <InputLabel htmlFor="scopeDropdown">Select a Scope</InputLabel>
      <Select
        value={selected && selected}
        id="scopeDropdown"
        label="Select a Scope"
        onChange={handleSelect}
      >
        <MenuItem value="Global">Global</MenuItem>
        <MenuItem value="Student devices">Student devices</MenuItem>
        <MenuItem value="Teacher devices">Teacher devices</MenuItem>
        <MenuItem value="Administration">Administration</MenuItem>
        <MenuItem value="Shared Student devices">
          Shared Student devices
        </MenuItem>
        <MenuItem value="Shared Teacher devices">
          Shared Teacher devices
        </MenuItem>
        <MenuItem value="Shared Administration">Shared Administration</MenuItem>
      </Select>
    </FormControl>
  );
}

export function DeployPolicy({ audience, close }) {
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [scope, setScope] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const postApiFromContext = usePostApiFromContext();
  useEffect(() => {
    if (scope && selectedProfiles.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedProfiles, scope]);

  const handleSelectScope = (event) => setScope(event.target.value);
  const handleSubmit = () => {
    const payload = getBackofficeTaskBasePayload();
    payload.detail = {
      task: "policy.backoffice.deploy_bulk_policy",
      kwargs: {
        scope: scope,
        profiles: selectedProfiles,
        tenantIds: audience.map((tenant) => {
          return tenant.id;
        }),
      },
    };
    postApiFromContext(`/tasks/backoffice/submit/`, payload).then(
      (response) => {
        close && close();
        setScope(false);
        setSelectedProfiles([]);
      }
    );
  };
  return (
    <Box>
      <ScopeDropdown selected={scope} handleSelect={handleSelectScope} />
      <ProfilesSelector handleSelect={setSelectedProfiles} />
      <Button onClick={handleSubmit} disabled={!isValid} variant="contained">
        Submit
      </Button>
    </Box>
  );
}

export function DeployPolicyProfilesPayload({ audience, close }) {
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [scope, setScope] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const postApiFromContext = usePostApiFromContext();
  useEffect(() => {
    if (scope && selectedProfiles.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedProfiles, scope]);

  const handleSelectScope = (event) => setScope(event.target.value);
  const handleSubmit = () => {
    const payload = getBackofficeTaskBasePayload();
    payload.detail = {
      task: "policy.backoffice.deploy_bulk_policy",
      kwargs: {
        scope: scope,
        profiles: selectedProfiles,
        tenantIds: audience.map((tenant) => {
          return tenant.id;
        }),
      },
    };
    postApiFromContext(`/tasks/backoffice/submit/`, payload).then(
      (response) => {
        close && close();
        setScope(false);
        setSelectedProfiles([]);
      }
    );
  };
  return (
    <Box>
      <ScopeDropdown selected={scope} handleSelect={handleSelectScope} />
      <PolicyProfilesPayloadDropzone handleSelect={setSelectedProfiles} />
      <Button onClick={handleSubmit} disabled={!isValid} variant="contained">
        Submit
      </Button>
    </Box>
  );
}

export function AppendToLocalAdminsPolicy({ audience, close }) {
  const [scope, setScope] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const postApiFromContext = usePostApiFromContext();
  useEffect(() => {
    if (scope && groupName) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [groupName, scope]);
  const handleSelectScope = (event) => setScope(event.target.value);
  const handleSubmit = () => {
    const payload = getBackofficeTaskBasePayload();
    payload.detail = {
      task: "policy.backoffice.backoffice_append_localAdmins",
      kwargs: {
        scope: scope,
        groupName: groupName,
        tenantIds: audience.map((tenant) => {
          return tenant.id;
        }),
      },
    };
    postApiFromContext(`/tasks/backoffice/submit/`, payload).then(
      (response) => {
        close && close();
        setScope(false);
        setGroupName("")
      }
    );
  };
  return (
    <Box>
      <ScopeDropdown selected={scope} handleSelect={handleSelectScope} />
      <TextField
        sx={{ mb: 2 }}
        label="Enter text"
        variant="outlined"
        fullWidth
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)} // Update the state on input change
      />
      <Button onClick={handleSubmit} disabled={!isValid} variant="contained">
        Submit
      </Button>
    </Box>
  );
}
