import React from "react";
import { Grid, Box, Typography, Paper } from "@mui/material";
import { Providers } from "@microsoft/mgt-element";
import { ReactComponent as LoginLogo } from "../images/loginMicrosoft.svg";
function Login() {
  const NumiatoLogo = require("../images/logo.png")
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 42,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            verticalAlign: "middle"
          }}
        >
        <Box sx={{m:3}} ><img src={NumiatoLogo} style={{width:350}}/></Box>
          <Typography component="h1" variant="h5" sx={{m:3}}>
            Welcome to Numiato Backoffice
          </Typography>
          <Box onClick={()=>Providers.globalProvider.login()} >
            <LoginLogo />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
