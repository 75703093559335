import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
function AppleTokensTable({ data }) {
  const [dataFlat, setDataFlat] = useState([]);
  const navigate= useNavigate()
  useEffect(() => {
    let info = data;
    info.map((line) => {
      line.general = line.management_info.general;
      line.vpp = line.management_info.vpp;
      line.asm = line.management_info.asm;
      line.mdm = line.management_info.mdm;
      line.scim = line.management_info.scim;
      line.onboarded = line.management_info?.pmonboarded || false;
      line.unternehmensportal = line.management_info.unternehmensportal;
      line.expiration = new Date(line.management_info.expiration);
      line.classroom = line.appleClassroom?.sftp_user
    });
    setDataFlat(info);
  }, [data]);
  const getNumiatoState = (status) => {
    switch (status) {
      case "1000":
      case "1001":
        return <Tooltip title={`Trial ${status}`}>🟠</Tooltip>;
      case "1010":
        return <Tooltip title={`OK ${status}`}>🟢</Tooltip>;
      case "1003":
        return <Tooltip title={`Limited ${status}`}>🔸</Tooltip>;
      case "1101":
      case "1110":
      case "1201":
        return <Tooltip title={`Expired ${status}`}>🔴</Tooltip>;
      case "1002":
      case "1012":
        return <Tooltip title={`Expiring ${status}`}>🟡</Tooltip>;
      default:
        return <Tooltip title={`Unknown ${status}`}>⚪️</Tooltip>;
    }
  };
  const getStatusIcon = (status) => {
    return status ? <span>🟢</span> : <span>🔴</span>;
  };

  const columns = [
    {
      field: "tenant_id",
      headerName: "Tenant Id",
      headerAlign: "center",
      width: 350,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 350,
    },
    {
      field: "numiato_status",
      headerName: "Numiato Status",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => getNumiatoState(params.row.numiato_status),
      align: "center",
    },
    {
      field: "general",
      headerName: "Active",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (params) => getStatusIcon(params?.value),
    },
    {
      field: "expiration",
      headerName: "Expiration",
      headerAlign: "center",
      type: "date",
      width: 150,
      align: "center",
      sort: "desc",
      // valueGetter: (params) => new Date(params?.value),
      // valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "classroom",
      headerName: "SFTP",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (params) => getStatusIcon(params?.value),
    },
    {
      field: "vpp",
      headerName: "VPP",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (params) => getStatusIcon(params?.value),
    },
    {
      field: "scim",
      headerName: "SCIM",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (params) => getStatusIcon(params?.value),
    },
    {
      field: "asm",
      headerName: "ASM",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (params) => getStatusIcon(params?.value),
    },
    {
      field: "mdm",
      headerName: "MDM",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (params) => getStatusIcon(params?.value),
    },
    {
      field: "unternehmensportal",
      headerName: "UP App",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (params) => getStatusIcon(params?.value),
    },
    {
      field: "domains",
      valueGetter: (value) => {
        try {
          return value.join();
        } catch {
          return "";
        }
      },
    },
    {
      field: "users",
      valueGetter: (value) => {
        try {
          return value.join();
        } catch {
          return "";
        }
      },
    },
  ];

  return (
    <>
      {data && (
        <Box
          sx={{
            py: 3,
            height: "88%",
            "& .table-head": {
              backgroundColor: "#474443",
              fontWeight: 700,
            },
          }}
        >
          <DataGrid
            rows={dataFlat}
            columns={columns}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 20 } },
              columns: {
                columnVisibilityModel: {
                  domains: false,
                  users: false,
                  tenant_id:false,
                },
              },
              sorting:{
                sortModel:[{field:'expiration',sort:'asc'}]
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: false,
                  // quickFilterValues:['vogl']
                },
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
              },
            }}
            // disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            onRowClick={(params)=>navigate(`/customers/${params.row.tenant_id}`)}
          />
        </Box>
      )}
    </>
  );
}

export default AppleTokensTable;
