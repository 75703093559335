import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { BackofficeContext } from "../../tools/BackofficeContext";
import { PageTitle } from "../system/layout/PageElements";
import { Cancel, CheckCircleOutline } from "@mui/icons-material";

function Authorizing() {
  const { prdToken, uatToken, locToken, authorized, setAuthorized } =
    useContext(BackofficeContext);
  useEffect(() => {
    if (prdToken !== null && uatToken !== null && locToken !== null) {
      setAuthorized(true);
    }
  }, [prdToken, uatToken, locToken]);

  return (
    <>
      <Container maxWidth="xl">
        <Toolbar />
        <Box>
          <PageTitle>Authorizing...</PageTitle>
          <Grid container spacing={2} sx={{ margin: 2 }}>
            <Grid item xs={12}  sx={{ paddingX: 2, display:"flex" }}>
              {prdToken === null ? (
                <CircularProgress size={20} />
              ) : prdToken ? (
                <CheckCircleOutline color="success" />
              ) : (
                <Cancel color="error" />
              )}
              <Typography sx={{paddingX:2}}>Production</Typography>
            </Grid>
            <Grid item xs={12}  sx={{ paddingX: 2, display:"flex" }}>
              {uatToken === null ? (
                <CircularProgress size={20} />
              ) : uatToken ? (
                <CheckCircleOutline color="success" />
              ) : (
                <Cancel color="error" />
              )}
              <Typography sx={{paddingX:2}}>Development</Typography>
            </Grid>
            <Grid item xs={12}  sx={{ paddingX: 2, display:"flex" }}>
              {locToken === null ? (
                <CircularProgress size={20} />
              ) : locToken ? (
                <CheckCircleOutline color="success" />
              ) : (
                <Cancel color="error" />
              )}
              <Typography sx={{paddingX:2}}>Local</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Authorizing;
