import React, { useState, useEffect, useContext } from "react";
import { PageTitle } from "../../components/system/layout/PageElements";
import TenantProfile from "../../components/customers/TenantProfile";
import { LoadingTable } from "../../components/system/layout/LoadingElements";
import { useParams } from "react-router-dom";
import { GetApi } from "../../tools/axiosFunctions";
import { BackofficeContext } from "../../tools/BackofficeContext";
function TenantProfilePage() {
  let { tenantId } = useParams();
  const {environment,refresh} = useContext(BackofficeContext)
  const [tenantInfo, setTenantInfo] = useState(false);
  useEffect(() => {
    GetApi(environment, `/auth/backoffice/tenant/${tenantId}`).then((response) =>
      setTenantInfo(response.data)
    );
  }, [tenantId,refresh]);

  return (
    <>
      <PageTitle>Numiato Customers</PageTitle>
      {tenantInfo ? <TenantProfile info={tenantInfo} /> : <LoadingTable />}
    </>
  );
}

export default TenantProfilePage;
