import {
  CheckCircleOutline,
  NewReleasesOutlined,
  RateReviewOutlined,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  styled,
  Box,
  Tooltip,
} from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";
import React, { useEffect, useState } from "react";

function DataTable({ data, display = "displayName", selectable }) {
  const [loadedData, setLoadedData] = useState(data);
  const [update, setUpdate] = useState(0);
  const [create, setCreate] = useState(0);
  useEffect(() => {
    setLoadedData(data);
    setCreate(0);
    setUpdate(0);
    setTimeout(() => {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        item.state == -1
          ? setCreate((p) => p + 1)
          : item.state > 0 && setUpdate((p) => p + 1);
      }
    }, 500);
  }, [data]);

  return (
    <>
      {loadedData &&
        (selectable ? (
          <Typography>
            {data.length - create - update} synced elements, {create.toString()}{" "}
            missing and {update.toString()} outdated.
          </Typography>
        ) : (
          <Typography>{loadedData.length} elements.</Typography>
        ))}
      {loadedData.map((item, index) => {
        return (
          <>
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`item${item.index}-content`}
                id={`item${item.index}`}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {"state" in item && (
                      <SelectedCheckbox
                        id={item.id}
                        variant="small"
                        selected={item.state !== 0}
                      />
                    )}
                    <Typography sx={{ py: 1.25 }}>{item[display]}</Typography>
                  </Box>
                  <Box sx={{ py: 1 }}>
                    {"state" in item && <ItemState state={item.state} />}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>{tableFromObject(item)}</AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </>
  );
}

export default DataTable;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const tableFromObject = (element) => {
  if (Object.keys(element).length == 0) {
    return <></>;
  }
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell variant="head">Property</StyledTableCell>
            <StyledTableCell variant="head">Value</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(element).map((e, index) => {
            if (!["state"].includes(e)) {
              return (
                <>
                  <TableRow
                    key={`q${Math.floor(Math.random() * 9999)}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ fontWeight: "bold" }}>{e}</TableCell>
                    <TableCell>
                      {element[e] &&
                      Object.getPrototypeOf(element[e]).isPrototypeOf(Object)
                        ? tableFromObject(element[e])
                        : Array.isArray(element[e])
                        ? element[e].map((ar) => <span>{typeof ar === 'string'?ar.toString():tableFromObject(ar)}</span>)
                        : element[e]?.toString()}
                    </TableCell>
                  </TableRow>
                </>
              );
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
function ItemState({ state }) {
  // <Typography> { 'state' in item && (item.state == 0 ?"d" :item.state >0?:)}</Typography>
  if (state == 0) {
    return (
      <Tooltip title="No action needed">
        {" "}
        <CheckCircleOutline sx={{ color: green[700], fontSize: 20 }} />
      </Tooltip>
    );
  } else if (state == -1) {
    // setCreate(p=>p+1)
    return (
      <Tooltip title="Object not found">
        {" "}
        <NewReleasesOutlined sx={{ color: red[700], fontSize: 20 }} />
      </Tooltip>
    );
  } else {
    // setUpdate(p=>p+1)
    return (
      <Tooltip title="Update needed">
        {" "}
        <RateReviewOutlined sx={{ color: yellow[900], fontSize: 20 }} />
      </Tooltip>
    );
  }
}
function SelectedCheckbox({ selected, ...attributes }) {
  const [isSelected, setIsSelected] = useState(selected === true);
  const handleOnClick = (e) => {
    e.stopPropagation();
    setIsSelected(e.target.checked);
  };
  return (
    <>
      <Checkbox
        checked={isSelected}
        defaultValue={false}
        onClick={handleOnClick}
        {...attributes}
      />
    </>
  );
}
