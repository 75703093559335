import React, { useState, useEffect } from "react";
import PageTemplate from "../../components/dataloading/PageTemplate";
import { Typography, Grid, IconButton } from "@mui/material";
import CreateSimpleButton from "../../components/dataloading/CreateSimpleButton";
import UpdateOnlySimpleButton from "../../components/dataloading/UpdateOnlySimpleButton";
import { RefreshOutlined } from "@mui/icons-material";
import { dynamicSort, comparisonState } from "../../tools/utilities";
import { GetApi } from "../../tools/axiosFunctions";
function Policies() {
  const [source, setSource] = useState("uat");
  const [target, setTarget] = useState("prd");
  const [sourceObjects, setSourceObjects] = useState(null);
  const [targetObjects, setTargetObjects] = useState(null);

  const [mRefresh, setMRefresh] = useState(0);
  const refresh = () => setMRefresh((p) => p + 1);
  const master = "name";
  const uri = "/policy/data/policies/";
  const uri_filters = "/policy/data/filters/";
  const [related, setRelated] = useState([]);
  useEffect(() => {
    GetApi(source, uri_filters).then((srcObjects) => {
      GetApi(target, uri_filters).then((trgObjects) => {
        setRelated([
          {
            key: "filter",
            source: srcObjects.data,
            target: trgObjects.data,
            master: "name",
            foreignKey: "id",
          },
        ]);
      });
    });
  }, [source, target, mRefresh]);

  const gatherData = () => {
    setTargetObjects([]);
    setSourceObjects([]);
    GetApi(source, uri_filters).then((srcObjects) => {
      GetApi(target, uri_filters).then((trgObjects) => {
        GetApi(source, uri).then((obj) => {
          let srcObj = obj.data.sort(dynamicSort(master));
          GetApi(target, uri).then((obj) => {
            const dstObj = obj.data.sort(dynamicSort(master));
            srcObj.map(
              (ob) =>
                (ob.state = comparisonState(ob, dstObj, master, [
                  {
                    key: "filter",
                    source: srcObjects.data,
                    target: trgObjects.data,
                    master: "name",
                    foreignKey: "id",
                  },
                ]))
            );
            setRelated([
              {
                key: "filter",
                source: srcObjects.data,
                target: trgObjects.data,
                master: "name",
                foreignKey: "id",
              },
            ]);
            setTargetObjects(dstObj);
          });
          setSourceObjects(srcObj);
        });
      });
    });
  };

  return (
    <>
      <Typography variant="h4">Policy Management - Policies</Typography>
      <Grid
        container
        sx={{ p: 2, mx: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <IconButton onClick={() => refresh()}>
          <RefreshOutlined />
        </IconButton>
        {sourceObjects && targetObjects && (
          <CreateSimpleButton
            sourceObjects={sourceObjects}
            targetObjects={targetObjects}
            target={target}
            source={source}
            uri={uri}
            related={related}
            refresh={refresh}
          />
        )}
        {sourceObjects && targetObjects && (
          <UpdateOnlySimpleButton
            target={target}
            sourceObjects={sourceObjects}
            targetObjects={targetObjects}
            uri={uri}
            refresh={refresh}
            master={master}
            related={related}
          />
        )}
      </Grid>
      <PageTemplate
        source={source}
        target={target}
        setSource={setSource}
        setTarget={setTarget}
        master={master}
        sourceObjects={sourceObjects}
        targetObjects={targetObjects}
        gatherData={gatherData}
        mRefresh={mRefresh}
      />
    </>
  );
}

export default Policies;
