import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
function CustomerTable({ data, openTenant }) {
  const navigate = useNavigate();
  const getNumiatoState = (status) => {
    switch (status) {
      case "1000":
      case "1001":
        return <Tooltip title={`Trial ${status}`}>🟠</Tooltip>;
      case "1010":
        return <Tooltip title={`OK ${status}`}>🟢</Tooltip>;
      case "1003":
        return <Tooltip title={`Limited ${status}`}>🔸</Tooltip>;
      case "1101":
      case "1110":
      case "1201":
        return <Tooltip title={`Expired ${status}`}>🔴</Tooltip>;
      case "1002":
      case "1012":
        return <Tooltip title={`Expiring ${status}`}>🟡</Tooltip>;
      default:
        return <Tooltip title={`Unknown ${status}`}>⚪️</Tooltip>;
    }
  };
  const getVis365Status = (status) => {
    switch (status) {
      case "510":
      case "501":
        return <Tooltip title={`Trial ${status}`}>🟠</Tooltip>;
      case "521":
      case "522":
        return <Tooltip title={`OK ${status}`}>🟢</Tooltip>;
      case "502":
      case "503":
        return <Tooltip title={`Expired ${status}`}>🔴</Tooltip>;
      case "600":
        return <Tooltip title={`Terminated ${status}`}>⛔️</Tooltip>;
      case "523":
        return <Tooltip title={`Expiring ${status}`}>🟡</Tooltip>;
      default:
        return <Tooltip title={`Unknown ${status}`}>⚪️</Tooltip>;
    }
  };
  const columns = [
    {
      field: "tenant_id",
      headerName: "Tenant Id",
      headerAlign: "center",
      width: 350,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 350,
    },
    {
      field: "numiato_status",
      headerName: "Numiato Status",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => getNumiatoState(params.row.numiato_status),
      align: "center",
    },
    {
      field: "vis365_status",
      headerName: "Vis365 Status",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => getVis365Status(params.row.vis365_status),
      align: "center",
    },
    {
      field: "association",
      headerName: "Association",
      headerAlign: "center",
      width: 350,
      align: "center",
    },
    {
      field: "domains",
      valueGetter: (value) => {
        try {
          return value.join();
        } catch {
          return "";
        }
      },
    },
    {
      field: "users",
      valueGetter: (value) => {
        try {
          return value.join();
        } catch {
          return "";
        }
      },
    },
   
  ];
  return (
    <>
      {data && (
        <Box
          sx={{
            py: 3,
            height: "88%",
            "& .table-head": {
              backgroundColor: "#474443",
              fontWeight: 700,
            },
          }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 20 } },
              columns: {
                columnVisibilityModel: {
                  domains: false,
                  users: false,
                },
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: false,
                  // quickFilterValues:['vogl']
                },
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
              },
            }}
            // disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            onRowClick={(params) =>
              navigate(`/customers/${params.row.tenant_id}`)
            }
            
          />
        </Box>
      )}
    </>
  );
}

export default CustomerTable;
