import React, { useState, useEffect, useContext } from "react";
import { PageTitle } from "../../components/system/layout/PageElements";
import CustomerTable from "../../components/customers/CustomerTable";
import { GetApi } from "../../tools/axiosFunctions";
import { LoadingTable } from "../../components/system/layout/LoadingElements";
import TenantProfile from "../../components/customers/TenantProfile";
import { BackofficeContext } from "../../tools/BackofficeContext";

function CustomersList() {
  const [customerData, setCustomerData] = useState(null);
  const [singleTenant, setSingleTenant] = useState(null);
  const { environment, refresh } = useContext(BackofficeContext);
  useEffect(() => {
    GetApi(environment, "/auth/backoffice/tenant/").then((response) =>
      setCustomerData(response.data)
    );
  }, [refresh]);
  const backToTable = () => setSingleTenant(null);
  return (
    <>
      <PageTitle>Numiato Customers</PageTitle>
      {customerData ? (
        singleTenant ? (
          <TenantProfile info={singleTenant} back={backToTable} />
        ) : (
          <CustomerTable data={customerData} openTenant={setSingleTenant} />
        )
      ) : (
        <LoadingTable />
      )}
    </>
  );
}

export default CustomersList;
