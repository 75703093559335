import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import UserButton from "./UserButton";
import { Drawer } from "@mui/material";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import EnvironmentIcon from "./EnvironmentIcon";
const drawerWidth = 250;

export default function Navbar() {
  const logo = require("../../images/Numiato_white.png")
  return (
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box component={Link} to="/" sx={{ flexGrow: 1 }}> <img src={logo} style={{height:"25px"}} alt="Numiato-logo" /></Box>
          <EnvironmentIcon/>
          <UserButton />
        </Toolbar>
      </AppBar>
)}
