import React, { useContext, useEffect, useState } from "react";
import { useGetApiFromContext } from "../../../tools/axiosFunctions";
import { BackofficeContext } from "../../../tools/BackofficeContext";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

function ListSelector({available=[],setAudience,title=""}) {

  const [selectedItems, setSelectedItems] = useState([]);
  const [availableItems, setAvailableItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
      setAvailableItems(available)
    }, [available])
    
    

  const not = (a, b) => {
    const setB = new Set(b.map((bb)=>bb.id));
    return a.filter((value) => !setB.has(value.id));
  };
  const intersection = (a, b) => {
    const setB = new Set(b.map((bb)=>bb.id));
    return a.filter((value) => setB.has(value.id));
  };
  const union = (a, b) => [...a, ...not(b, a)];

  const availableChecked = intersection(checkedItems, availableItems);
  const selectedChecked = intersection(checkedItems, selectedItems);

  const handleToggle = (value) => () => {
    const currentIndex = checkedItems.findIndex(
      (t) => t.id === value.id
    );
    let newChecked = [...checkedItems];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedItems(newChecked);
  };

  const numberOfChecked = (items) => intersection(checkedItems, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setCheckedItems(not(checkedItems, items));
    } else {
      setCheckedItems(union(checkedItems, items));
    }
  };

  const handleCheckedSelected = () => {
    setSelectedItems(union(selectedItems,availableChecked));
    // setSelectedItems(selectedItems.concat(availableChecked));
    setAvailableItems(not(availableItems, availableChecked));
    setCheckedItems(not(checkedItems, availableChecked));
  };

  const handleCheckedAvailable = () => {
    setAvailableItems(union(availableItems,selectedChecked));
    // setAvailableItems(availableItems.concat(selectedChecked));
    setSelectedItems(not(selectedItems, selectedChecked));
    setCheckedItems(not(checkedItems, selectedChecked));
  };

  const listCustomList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List sx={{ overflow: "auto",height:300 }} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `all-item-${value.id}-label`;
          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    checkedItems.findIndex((p) => p.id === value.id) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.label} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Grid item xs={5}>
          {listCustomList(`Available ${title}`, availableItems)}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" sx={{ alignItems: "center" }}>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedSelected}
              disabled={availableChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedAvailable}
              disabled={selectedChecked.length === 0}
              aria-label="move selected right"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={()=>setAudience(selectedItems)}
              disabled={selectedItems.length === 0}
              aria-label="set selected"
            >
              Set
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5}>{listCustomList(`Selected ${title}`, selectedItems)}</Grid>
      </Grid>
    </>
  );
}

export default ListSelector;
