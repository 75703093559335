import React, { useContext, useEffect, useState } from "react";
import TenantPicker from "../../components/numiato/TenantPicker";
import {
  PageSubtitle,
  PageTitle,
} from "../../components/system/layout/PageElements";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { BackofficeContext } from "../../tools/BackofficeContext";
import HomeMenu from "../../components/numiato/home/HomeMenu";
import { ChevronLeft } from "@mui/icons-material";
import TaskList from "../../components/numiato/history/TaskList";
import BackofficeTasksTenant from "../../components/numiato/backoffice/BackofficeTasksTenant";
function NumiatoRealm() {
  const [tenantName, setTenantName] = useState(false);
  const [md, setMd] = useState(false);
  const { setTenantId, refresh } = useContext(BackofficeContext);
  useEffect(() => {
    changeTenant();
  }, [refresh]);
  const changeTenant = () => {
    setTenantId(false);
    setTenantName(false);
    setMd(false);
  };
  const backHome = () => setMd(false);
  const showModule = () => {
    switch (md) {
      case "History":
        return <><TaskList/></>;
        break;
      case "Backoffice Tasks":
        return <BackofficeTasksTenant />
        break;
      default:
        return <HomeMenu select={setMd} />;
        break;
    }
  };
  return (
    <>
      {tenantName ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <PageTitle>Numiato View: {tenantName}</PageTitle>
            <Button variant="outlined" onClick={changeTenant}>
              Change
            </Button>
          </Box>
          <Box sx={{ padding: 2 }}>
            {md && (
              <PageSubtitle>
                <IconButton onClick={backHome} sx={{ marginX: 2 }}>
                  <ChevronLeft />
                </IconButton>
                {md}
              </PageSubtitle>
            )}
            {showModule()}
          </Box>
        </>
      ) : (
        <>
          <PageTitle>Numiato View</PageTitle>
          <Box sx={{ paddingTop: 2 }}>
            <Typography variant="body1" sx={{ paddingY: 2 }}>
              This view allows to use Numiato as a tenant, select the tenant
              below:
            </Typography>
            <TenantPicker setName={setTenantName} />
          </Box>
        </>
      )}
    </>
  );
}

export default NumiatoRealm;
