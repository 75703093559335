import React, { useContext, useState } from "react";
import { BackofficeContext } from "../../tools/BackofficeContext";
import {
  CodeOutlined,
  ExtensionOutlined,
  GppGoodOutlined,
} from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

function EnvironmentIcon() {
  const { environment,setEnvironment,makeRefresh } = useContext(BackofficeContext);
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null)
  const toggleOpen = () => setOpen(!open);
  const handleOpen = (event)=>{
    setAnchor(event.currentTarget);
    setOpen(true)
  }
  const setEnv=(env)=>{
    setEnvironment(env)
    makeRefresh()
    toggleOpen()
  }
  const EnvIcon = () => {
    switch (environment) {
      case "PRD":
        return <GppGoodOutlined sx={{ color: "white" }} />;
      case "UAT":
        return <CodeOutlined sx={{ color: "white" }} />;
      case "LOC":
        return <ExtensionOutlined sx={{ color: "white" }} />;
    }
  };
  const envText = () => {
    switch (environment) {
      case "PRD":
        return "Production";
      case "UAT":
        return "Development";
      case "LOC":
        return "Localhost";
    }
  };

  return (
    <>
      <Tooltip title={envText()}>
        <IconButton onClick={handleOpen}>
          <EnvIcon />
        </IconButton>
      </Tooltip>
      <Menu id="basic-menu" open={open} onClose={toggleOpen} anchorEl={anchor}>
        <MenuItem onClick={()=>setEnv("PRD")}>Production</MenuItem>
        <MenuItem onClick={()=>setEnv("UAT")}>Development</MenuItem>
        <MenuItem onClick={()=>setEnv("LOC")}>Localhost</MenuItem>
      </Menu>
    </>
  );
}

export default EnvironmentIcon;
