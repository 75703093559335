import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { PageTitle } from "../components/system/layout/PageElements";
import TenantsSelector from "../components/numiato/bulktasks/TenantsSelector";
import AudiencePopup from "../components/numiato/bulktasks/AudiencePopup";
import BackofficeTask from "../components/numiato/backoffice/BackofficeTask";
import {
  AppendToLocalAdminsPolicy,
  BulkReplaceApplication,
  DeployPolicy,
  DeployPolicyProfilesPayload,
} from "../components/numiato/bulktasks/BulkTaskComponents";
import { BackofficeContext } from "../tools/BackofficeContext";
function BulkTasks() {
  const { environment } = useContext(BackofficeContext);
  const [audience, setAudience] = useState([]);
  const [selectedTask, setSelectedTask] = useState(false);
  useEffect(() => {
    setAudience([]);
  }, [environment]);
  const close = () => setSelectedTask(false);

  return (
    <Box sx={{ py: 2 }}>
      <PageTitle>Bulk Tasks</PageTitle>
      <Grid container spacing={1}>
        {audience.length <= 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6">Select the audience</Typography>
            <TenantsSelector setAudience={setAudience} />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              spacing={2}
              sx={{ display: "flex", justifyContent: "end", px: 2 }}
            >
              <Typography>{`There are ${audience.length} tenants selected`}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "end", px: 2 }}
            >
              <AudiencePopup audience={audience} />
              <Button variant="outlined" onClick={() => setAudience([])}>
                Change
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ paddingY: 2, maxHeight: "75vh", overflowY: "auto" }}>
                <BackofficeTask
                  id="replaceApp"
                  title="Replace Application"
                  selected={selectedTask}
                  onExpand={setSelectedTask}
                >
                  <BulkReplaceApplication audience={audience} close={close} />
                </BackofficeTask>
                <BackofficeTask
                  id="submitPolicy"
                  title="Submit Policy"
                  selected={selectedTask}
                  onExpand={setSelectedTask}
                >
                  <DeployPolicy audience={audience} close={close} />{" "}
                </BackofficeTask>
                <BackofficeTask
                  id="submitPolicyPayload"
                  title="Submit Policy with Payload"
                  selected={selectedTask}
                  onExpand={setSelectedTask}
                >
                  <DeployPolicyProfilesPayload
                    audience={audience}
                    close={close}
                  />{" "}
                </BackofficeTask>
                <BackofficeTask
                  id="addLocalAdmins"
                  title="Add Member to Local Admins policy"
                  description={`First will search the given group name on the tenants, if found it will try to append it to the Local Admins policy on the provided scope. \n
                    If there is no policy under that scope, it will be created when the group is found as member.`}
                  selected={selectedTask}
                  onExpand={setSelectedTask}
                >
                  <AppendToLocalAdminsPolicy
                    audience={audience}
                    close={close}
                  />{" "}
                </BackofficeTask>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default BulkTasks;
