import {
  Box,
  Button,
  Modal,
  Paper,
  Typography,
  LinearProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { createSimple } from "../../pages/datamigration/functions";

function CreateSimpleButton({
  target,
  sourceObjects,
  uri,
  refresh,
  related
}) {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [success, setSuccess] = useState([]);
  const [failure, setFailure] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const collectSelected = () => {
    let s = [];
    for (let i = 0; i < sourceObjects.length; i++) {
      const item = sourceObjects[i];
      if (document.getElementById(item.id).checked) {
        item.state == -1 && s.push(item);
      }
    }
    setSelected(s);
  };

  const open = () => {
    setShowModal(true);
    collectSelected();
  };
  const close = () => {
    if(!inProgress) {
    setShowModal(false)
    setSuccess([])
    setFailure([])
    setProgress(0)}
  };
  const successful = (e) => setSuccess((p) => [e, ...p]);
  const failed = (e) => setFailure((p) => [e, ...p]);
  const create = () => {
    setInProgress(true);
    // CREATE THE ELEMENTS
    createSimple(target,selected,uri,setProgress,successful,failed,related)
  };

  useEffect(() => {
    if(success.length+failure.length >= selected.length && inProgress){
      setTimeout(() => {
        setInProgress(false);
        refresh();
        setSuccess([]);
        setFailure([]);
        setShowModal(false);
        setProgress(0);
      }, 3000);
    } 
  }, [success,failure])
  
  return (
    <>
      <Button variant="outlined" onClick={open} sx={{mx:1}}>
        Create
      </Button>
      <Modal open={showModal} onClose={close}>
        <Paper
          elevation="3"
          sx={{
            p: 2,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: 400,
          }}
        >
          <Typography variant="h6">Creating elements</Typography>
          {inProgress ? (
            <>
              <LinearProgress
                value={((success.length+failure.length) / selected.length) * 100}
                variant="determinate"
                sx={{ my: 4, borderRadius: 5, height: 10 }}
              />
            </>
          ) : (
            <>
              <Typography>{selected.length} items will be created.</Typography>
              <Button variant="outlined" sx={{ my: 1 }} onClick={create}>
                Confirm
              </Button>
            </>
          )}
        </Paper>
      </Modal>
    </>
  );
}

export default CreateSimpleButton;
