import React, { useContext } from "react";
import { PageTitle } from "../components/system/layout/PageElements";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography, } from "@mui/material";
import { Label } from "@mui/icons-material";
import { BackofficeContext } from "../tools/BackofficeContext";

function Settings() {
  const {environment,setEnvironment} = useContext(BackofficeContext)
  const handleSaveEnvironment = (e) => {setEnvironment(e.target.value)};
  return (
    <>
      <Box sx={{ paddingY: 2 }}>
        <PageTitle>Backoffice Settings</PageTitle>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4}>
          <Typography variant="h6">Environment</Typography>
          <Box sx={{py:2}}>
          <Typography variant="mono" >
              Select the environment where the Backoffice will connect to.
            </Typography>
            <Select
              defaultValue={"PRD"}
              id="environment-Select"
              name="environment-select"
              onChange={handleSaveEnvironment}
              sx={{my:1}}
              value={environment}
            >
              <MenuItem value="PRD" >Production</MenuItem>
              <MenuItem value="UAT">Development</MenuItem>
              <MenuItem value="LOC">Localhost</MenuItem>
            </Select>
            </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Settings;
