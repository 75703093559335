import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import global_en from '../translations/EN.json'
import global_de from '../translations/DE.json'
import axios from 'axios';

i18n
// .use(LanguageDetector)
.use(initReactI18next)
.init({
   interpolation: { escapeValue: false },
   fallbackLng: "en",
  //  debug: true,
   resources:{
     en: {
       global: global_en
     },
     de: {
       global: global_de
     }
    //  },
    //  es:{
    //      global: global_es,
    //      legal: legal_es,
    //     },
   },
});

export default i18n;


export const I18nDecorator = (storyFn) => (
  <I18nextProvider i18n={i18n}>{storyFn()}</I18nextProvider>
);


export const getTranslations = () => {
  axios
    .get(`https://api.numiato.de/settings/translations`)
    .then((translations) => {
      // DE
      i18n.addResourceBundle(
        "de",
        "global",
        { ...translations.data.de },
        true
      );
      // EN
      i18n.addResourceBundle(
        "en",
        "global",
        { ...translations.data.en },
        true
      );
    })
    .catch((e)=>console.log(e))
    axios
    .get(`https://dev-api.numiato.de/settings/translations`)
    .then((translations) => {
      // DE
      i18n.addResourceBundle(
        "de",
        "global",
        { ...translations.data.de },
        true
      );
      // EN
      i18n.addResourceBundle(
        "en",
        "global",
        { ...translations.data.en },
        true
      );
    })
    .catch((e)=>console.log(e))
    axios
    .get(`http://localhost:8000/settings/translations`)
    .then((translations) => {
      // DE
      i18n.addResourceBundle(
        "de",
        "global",
        { ...translations.data.de },
        true
      );
      // EN
      i18n.addResourceBundle(
        "en",
        "global",
        { ...translations.data.en },
        true
      );
    })
    .catch((e)=>console.log(e))
};