import React, {  useState } from "react";
import PageTemplate from "../../components/dataloading/PageTemplate";
import { Typography,Grid,IconButton } from "@mui/material";
import CreateSimpleButton from "../../components/dataloading/CreateSimpleButton";
import UpdateOnlySimpleButton from "../../components/dataloading/UpdateOnlySimpleButton";
import { RefreshOutlined } from "@mui/icons-material";
import { GetApi } from "../../tools/axiosFunctions";
import { dynamicSort,comparisonState } from "../../tools/utilities";

function Metric() {
  const [source, setSource] = useState("uat");
  const [target, setTarget] = useState("prd");
  const [sourceObjects, setSourceObjects] = useState(null);
  const [targetObjects, setTargetObjects] = useState(null);

  const [mRefresh, setMRefresh] = useState(0);
  const refresh = () => setMRefresh((p) => p + 1);
  const master = "display_name_en";
  const uri = "/metrics/data/metrics/";
  const gatherData = ()=>{
    setTargetObjects([])
    setSourceObjects([])
    GetApi(source, uri).then((obj) => {
      let srcObj = obj.data.sort(dynamicSort(master));
      GetApi(target, uri).then((obj) => {
        const dstObj = obj.data.sort(dynamicSort(master));
        srcObj.map(
          (ob) => (ob.state = comparisonState(ob, dstObj, master))
        );
        setTargetObjects(dstObj);
      });
      setSourceObjects(srcObj);
    });
  }
  return (
    <>
      <Typography variant="h4">Device Management - Metrics</Typography>
      <Grid
        container
        sx={{ p: 2, mx: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <IconButton onClick={() => refresh()}>
          <RefreshOutlined />
        </IconButton>
        {sourceObjects && targetObjects && (
          <CreateSimpleButton
            sourceObjects={sourceObjects}
            targetObjects={targetObjects}
            target={target}
            source={source}
            uri={uri}
            refresh={refresh}
          />
        )}
        {sourceObjects && targetObjects && (
          <UpdateOnlySimpleButton
            target={target}
            sourceObjects={sourceObjects}
            targetObjects={targetObjects}
            uri={uri}
            refresh={refresh}
            master={master}
          />
        )}
      </Grid>
      <PageTemplate
        source={source}
        target={target}
        setSource={setSource}
        setTarget={setTarget}
        master={master}
        sourceObjects={sourceObjects}
        targetObjects={targetObjects}
        gatherData={gatherData}
        mRefresh={mRefresh}
      />
    </>
  );
}

export default Metric