import React, { useContext } from "react";
import { BackofficeContext } from "../../../tools/BackofficeContext";
import { useState } from "react";
import BackofficeTask, { BackofficeTaskList } from "./BackofficeTask";
import { GroupCategoryAssignment, ReplaceApplication } from "./TaskComponents";
import { usePostApiAsTenant } from "../../../tools/axiosFunctions";

function BackofficeTasksTenant() {
  const { tenantId } = useContext(BackofficeContext);
  const [payload, setPayload] = useState(false);
  const [selected, setSelected] = useState(false);
  const postApiAsTenant = usePostApiAsTenant();
  const handleSelectTask = (task) => {
    setSelected(task);
    setPayload(false);
  };

  const handleSubmit = () => {
    postApiAsTenant("/tasks/backoffice/submit/", payload).then(() => {
      setSelected(false);
    });
  };

  return (
    <BackofficeTaskList isValid={payload} handleSubmit={handleSubmit}>
      <BackofficeTask
        id="replaceApp"
        selected={selected}
        onExpand={handleSelectTask}
        title={"Replace application"}
      >
        <ReplaceApplication setPayload={setPayload} />
      </BackofficeTask>
      <BackofficeTask
        id="groupCategoryAssignment"
        selected={selected}
        onExpand={handleSelectTask}
        title={"Assign Category to Group Members"}
      >
        <GroupCategoryAssignment setPayload={setPayload} />
      </BackofficeTask>
    </BackofficeTaskList>
  );
}

export default BackofficeTasksTenant;
