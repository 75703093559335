import React from "react";
import { Box, Typography, Button,Container } from "@mui/material";
import { Link } from "react-router-dom";
function underconstruction() {
  const developerImage = require("../images/developer.png")
  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100%",
      }}
    >
      <Container maxWidth="xl">
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            mb: 3,
            textAlign: "center",
          }}
        >
          <img
            alt="Under development"
            src={developerImage}
            style={{
              display: "inline-block",
              maxWidth: "100%",
              width: 400,
            }}
          />
        </Box>
        <Typography align="center" sx={{ mb: 3 }} variant="h3">
          Oops! The page you are looking for is not there yet
        </Typography>
        <Typography align="center" color="text.secondary" variant="body1">
          Our developer is quite busy these days and this page has not yet been completed. <br/>
          Buy him a coffee, maybe that makes him code faster
        </Typography>
        <Button component={Link} to="/" sx={{ mt: 3 }} variant="contained">
          Go back to dashboard
        </Button>
      </Box>
      </Container>
    </Box>
  );
}

export default underconstruction;
